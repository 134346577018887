<template>
  <div class="page">
    <h2 class="accent--text">
      <v-icon color="primary" class="ml-3" large>mdi-account</v-icon>
      contact us
      <back-button />
    </h2>

    <p v-if="table.loading">
      <v-progress-linear
        indeterminate
        color="blue darken-2"
      ></v-progress-linear>
    </p>
    <table v-else class="table-info white">
      <tr>
        <td>
          <strong class="accent--text">name</strong>
        </td>
        <td>{{ singleSocila.name }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">description</strong>
        </td>
        <td>{{ singleSocila.description }}</td>
      </tr>
     <tr>
        <td>
          <strong class="accent--text">phone</strong>
        </td>
        <td>{{ singleSocila.phone }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">twitter</strong>
        </td>
        <td>{{ singleSocila.twitter }}</td>
      </tr>
       <tr>
        <td>
          <strong class="accent--text">whatsapp</strong>
        </td>
        <td>{{ singleSocila.whatsapp }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">telegram</strong>
        </td>
        <td>{{ singleSocila.telegram }}</td>
      </tr>
       <tr>
        <td>
          <strong class="accent--text">email</strong>
        </td>
        <td>{{ singleSocila.email }}</td>
      </tr>
       <tr>
        <td>
          <strong class="accent--text">snapchat</strong>
        </td>
        <td>{{ singleSocila.snapchat }}</td>
      </tr>
      <tr>
        <td>
          <strong class="accent--text">instagram</strong>
        </td>
        <td>{{ singleSocila.instagram }}</td>
      </tr>
    </table>
    <router-link
          :to="{
            name: 'edit-social',
          }"
        >
          <v-btn rounded color="teal darken-4" dark> Edit-social </v-btn>
        </router-link>
  </div>
</template>


<script>
import { mapGetters, mapActions } from "vuex";
export default {
    data: function(){
        return {
            table: {
                loading: false
            }
        }
    },
     methods: {
    ...mapActions('social' ,["fetchContact"]),
  },
  computed: mapGetters('social' ,["singleSocila"]),
  created() {
    this.fetchContact();
  },
}
</script>